import { ARBITRUM, AVALANCHE, CALABI_TESTNET, FRAX_MAINNET, FRAX_TESTNET } from "./chains"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getIsSyntheticsSupported(chainId: number) {
    return false
}

export function getIsV1Supported(chainId: number) {
    return [FRAX_TESTNET, CALABI_TESTNET, FRAX_MAINNET, ARBITRUM].includes(chainId)
}
