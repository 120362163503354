import { ethers } from "ethers"
import sample from "lodash/sample"
import type { NetworkMetadata } from "lib/wallets"
import { isDevelopment } from "./env"
import {
    ARBITRUM,
    AVALANCHE,
    AVALANCHE_FUJI,
    BSC_MAINNET,
    BSC_TESTNET,
    ETH_MAINNET,
    ARBITRUM_GOERLI,
    FRAX_TESTNET,
    FRAX_MAINNET,
    CALABI_TESTNET,
} from "./static/chains"

export * from "./static/chains"

const { parseEther } = ethers

export const ENV_ARBITRUM_RPC_URLS = import.meta.env.VITE_APP_ARBITRUM_RPC_URLS
export const ENV_AVALANCHE_RPC_URLS = import.meta.env.VITE_APP_AVALANCHE_RPC_URLS

// TODO take it from web3
export const DEFAULT_CHAIN_ID = FRAX_TESTNET
export const CHAIN_ID = DEFAULT_CHAIN_ID

export const SUPPORTED_CHAIN_IDS = [FRAX_TESTNET]

if (isDevelopment()) {
    SUPPORTED_CHAIN_IDS.push(ARBITRUM, CALABI_TESTNET, AVALANCHE_FUJI)
}

export const IS_NETWORK_DISABLED = {
    [ARBITRUM]: false,
    [FRAX_TESTNET]: false,
    [CALABI_TESTNET]: false,
    [FRAX_MAINNET]: false,
    [AVALANCHE]: false,
    [BSC_MAINNET]: false,
}

export const CHAIN_NAMES_MAP = {
    [FRAX_TESTNET]: "Fraxtal Testnet",
    [CALABI_TESTNET]: "Mantle Testnet",
    [FRAX_MAINNET]: "Fraxtal Mainnet",
    [ARBITRUM_GOERLI]: "Arbitrum Goerli",
    [ARBITRUM]: "Arbitrum",
}

// added to maxPriorityFeePerGas
// applied to EIP-1559 transactions only
// is also applied to the execution fee calculation
export const GAS_PRICE_PREMIUM_MAP = {
    [ARBITRUM]: 0n,
    [FRAX_TESTNET]: 0n,
    [CALABI_TESTNET]: 0n,
    [FRAX_MAINNET]: 0n,
    [AVALANCHE]: 3000000000n, // 3 gwei
}

// added to gasPrice
// applied to *non* EIP-1559 transactions only
//
// it is *not* applied to the execution fee calculation, and in theory it could cause issues
// if gas price used in the execution fee calculation is lower
// than the gas price used in the transaction (e.g. create order transaction)
// then the transaction will fail with InsufficientExecutionFee error.
// it is not an issue on Arbitrum though because the passed gas price does not affect the paid gas price.
// for example if current gas price is 0.1 gwei and UI passes 0.5 gwei the transaction
// Arbitrum will still charge 0.1 gwei per gas
//
// it doesn't make much sense to set this buffer higher than the execution fee buffer
// because if the paid gas price is higher than the gas price used in the execution fee calculation
// and the transaction will still fail with InsufficientExecutionFee
//
// this buffer could also cause issues on a blockchain that uses passed gas price
// especially if execution fee buffer and lower than gas price buffer defined bellow
export const GAS_PRICE_BUFFER_MAP = {
    [ARBITRUM]: 2000n, // 20%
    [FRAX_TESTNET]: 2000n, // 20%
    [CALABI_TESTNET]: 0n,
    [FRAX_MAINNET]: 2000n, // 20%
}

/*
  that was a constant value in ethers v5, after ethers v6 migration we use it as a minimum for maxPriorityFeePerGas
*/
export const MAX_PRIORITY_FEE_PER_GAS_MAP: Record<number, bigint | undefined> = {
    [ARBITRUM]: 1500000000n,
    [AVALANCHE]: 1500000000n,
    [AVALANCHE_FUJI]: 1500000000n,
    [FRAX_TESTNET]: 1500000000n,
    [FRAX_MAINNET]: 1500000000n,
    [CALABI_TESTNET]: 1500000000n,
}

// added to maxPriorityFeePerGas
// applied to EIP-1559 transactions only
// is not applied to execution fee calculation
export const MAX_FEE_PER_GAS_MAP = {
    [AVALANCHE]: 200000000000n, // 200 gwei
}

export const HIGH_EXECUTION_FEES_MAP = {
    [ARBITRUM]: 5, // 5 USD
    [AVALANCHE]: 5, // 5 USD
    [AVALANCHE_FUJI]: 5, // 5 USD
    [FRAX_TESTNET]: 5, // 5 USD
    [FRAX_MAINNET]: 5, // 5 USD
    [CALABI_TESTNET]: 5, // 5 USD
}

export const EXCESSIVE_EXECUTION_FEES_MAP = {
    [ARBITRUM]: 10, // 10 USD
    [AVALANCHE]: 10, // 10 USD
    [AVALANCHE_FUJI]: 10, // 10 USD
    [FRAX_TESTNET]: 10, // 10 USD
    [FRAX_MAINNET]: 10, // 10 USD
    [CALABI_TESTNET]: 10, // 10 USD
}

export const NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR = {
    [ARBITRUM]: 10n ** 29n * 5n,
    [AVALANCHE]: 10n ** 29n * 35n,
    [AVALANCHE_FUJI]: 10n ** 29n * 2n,
    [FRAX_TESTNET]: 10n ** 29n * 2n,
    [FRAX_MAINNET]: 10n ** 29n * 2n,
    [CALABI_TESTNET]: 10n ** 29n * 2n,
} as const

export const EXECUTION_FEE_CONFIG_V2: {
    [chainId: number]: {
        shouldUseMaxPriorityFeePerGas: boolean
        defaultBufferBps?: number
    }
} = {
    [AVALANCHE]: {
        shouldUseMaxPriorityFeePerGas: true,
        defaultBufferBps: 1000, // 10%
    },
    [AVALANCHE_FUJI]: {
        shouldUseMaxPriorityFeePerGas: true,
        defaultBufferBps: 1000, // 10%
    },
    [ARBITRUM]: {
        shouldUseMaxPriorityFeePerGas: false,
        defaultBufferBps: 3000, // 30%
    },
    [ARBITRUM_GOERLI]: {
        shouldUseMaxPriorityFeePerGas: false,
        defaultBufferBps: 1000, // 10%
    },
    [FRAX_TESTNET]: {
        shouldUseMaxPriorityFeePerGas: false,
        defaultBufferBps: 1000, // 10%
    },
    [FRAX_MAINNET]: {
        shouldUseMaxPriorityFeePerGas: false,
        defaultBufferBps: 1000, // 10%
    },
    [CALABI_TESTNET]: {
        shouldUseMaxPriorityFeePerGas: false,
        defaultBufferBps: 1000, // 10%
    },
}

const constants = {
    [ARBITRUM_GOERLI]: {
        nativeTokenSymbol: "ETH",
        wrappedTokenSymbol: "WETH",
        defaultCollateralSymbol: "USDC",
        defaultFlagOrdersEnabled: false,
        positionReaderPropsLength: 9,
        v2: true,

        SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        // contract requires that execution fee be strictly greater than instead of gte
        DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
    },

    [ARBITRUM]: {
        nativeTokenSymbol: "ETH",
        wrappedTokenSymbol: "WETH",
        defaultCollateralSymbol: "USDC.e",
        defaultFlagOrdersEnabled: false,
        positionReaderPropsLength: 9,
        v2: true,

        SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        // contract requires that execution fee be strictly greater than instead of gte
        DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
    },

    [FRAX_TESTNET]: {
        nativeTokenSymbol: "frxETH",
        wrappedTokenSymbol: "wfrxETH",
        defaultCollateralSymbol: "FRAX",
        defaultFlagOrdersEnabled: false,
        positionReaderPropsLength: 9,
        v2: false,

        SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        // contract requires that execution fee be strictly greater than instead of gte
        DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
    },

    [CALABI_TESTNET]: {
        nativeTokenSymbol: "frxETH",
        wrappedTokenSymbol: "wfrxETH",
        defaultCollateralSymbol: "FRAX",
        defaultFlagOrdersEnabled: false,
        positionReaderPropsLength: 9,
        v2: false,

        SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        // contract requires that execution fee be strictly greater than instead of gte
        DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
    },

    [FRAX_MAINNET]: {
        nativeTokenSymbol: "frxETH",
        wrappedTokenSymbol: "wfrxETH",
        defaultCollateralSymbol: "FRAX",
        defaultFlagOrdersEnabled: false,
        positionReaderPropsLength: 9,
        v2: true,

        SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
        // contract requires that execution fee be strictly greater than instead of gte
        DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
    },

    [AVALANCHE]: {
        nativeTokenSymbol: "AVAX",
        wrappedTokenSymbol: "WAVAX",
        defaultCollateralSymbol: "USDC",
        defaultFlagOrdersEnabled: true,
        positionReaderPropsLength: 9,
        v2: true,

        SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
        INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
        // contract requires that execution fee be strictly greater than instead of gte
        DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
    },

    [AVALANCHE_FUJI]: {
        nativeTokenSymbol: "AVAX",
        wrappedTokenSymbol: "WAVAX",
        defaultCollateralSymbol: "USDC",
        defaultFlagOrdersEnabled: true,
        positionReaderPropsLength: 9,
        v2: true,

        SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
        INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.01"),
        // contract requires that execution fee be strictly greater than instead of gte
        DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0100001"),
    },
}

const ALCHEMY_WHITELISTED_DOMAINS = ["gmx.io", "app.gmx.io"]

export const RPC_PROVIDERS = {
    [FRAX_MAINNET]: ["https://rpc.frax.com"],
    [FRAX_TESTNET]: ["https://rpc.testnet.frax.com"],
    [CALABI_TESTNET]: ["https://rpc.sepolia.mantle.xyz"],
    [ETH_MAINNET]: ["https://rpc.ankr.com/eth"],
    [BSC_MAINNET]: ["https://bsc-dataseed.binance.org"],
    [BSC_TESTNET]: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    [ARBITRUM]: ["https://arb1.arbitrum.io/rpc"],
    [ARBITRUM_GOERLI]: ["https://goerli-rollup.arbitrum.io/rpc"],
    [AVALANCHE]: ["https://api.avax.network/ext/bc/C/rpc"],
    [AVALANCHE_FUJI]: ["https://avalanche-fuji-c-chain.publicnode.com", "https://api.avax-test.network/ext/bc/C/rpc"],
}

export const FALLBACK_PROVIDERS = {
    [ARBITRUM]: ENV_ARBITRUM_RPC_URLS ? JSON.parse(ENV_ARBITRUM_RPC_URLS) : [getAlchemyArbitrumHttpUrl()],
    [FRAX_MAINNET]: ["https://rpc.frax.com"],
    [FRAX_TESTNET]: ["https://rpc.testnet.frax.com"],
    [CALABI_TESTNET]: ["https://rpc.sepolia.mantle.xyz"],
    [AVALANCHE]: ENV_AVALANCHE_RPC_URLS ? JSON.parse(ENV_AVALANCHE_RPC_URLS) : [getAlchemyAvalancheHttpUrl()],
    [ARBITRUM_GOERLI]: ["https://arb-goerli.g.alchemy.com/v2/cZfd99JyN42V9Clbs_gOvA3GSBZH1-1j"],
}

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
    [BSC_MAINNET]: {
        chainId: "0x" + BSC_MAINNET.toString(16),
        chainName: "BSC",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[BSC_MAINNET],
        blockExplorerUrls: ["https://bscscan.com"],
    },
    [FRAX_TESTNET]: {
        chainId: "0x" + FRAX_TESTNET.toString(16),
        chainName: "Fraxtal Holesky Testnet",
        nativeCurrency: {
            name: "frxETH",
            symbol: "frxETH",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[FRAX_TESTNET],
        blockExplorerUrls: ["https://holesky.fraxscan.io/"],
    },
    [CALABI_TESTNET]: {
        chainId: "0x" + CALABI_TESTNET.toString(16),
        chainName: "Mantle Testnet",
        nativeCurrency: {
            name: "frxETH",
            symbol: "frxETH",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[CALABI_TESTNET],
        blockExplorerUrls: ["https://sepolia.mantle.xyz/"],
    },
    [FRAX_MAINNET]: {
        chainId: "0x" + FRAX_MAINNET.toString(16),
        chainName: "Fraxtal Mainnet",
        nativeCurrency: {
            name: "frxETH",
            symbol: "frxETH",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[FRAX_MAINNET],
        blockExplorerUrls: ["https://fraxscan.io/"],
    },
    [ARBITRUM_GOERLI]: {
        chainId: "0x" + ARBITRUM_GOERLI.toString(16),
        chainName: "Arbitrum Goerli Testnet",
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[ARBITRUM_GOERLI],
        blockExplorerUrls: ["https://goerli.arbiscan.io/"],
    },
    [ARBITRUM]: {
        chainId: "0x" + ARBITRUM.toString(16),
        chainName: "Arbitrum",
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[ARBITRUM],
        blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
    },
    [AVALANCHE]: {
        chainId: "0x" + AVALANCHE.toString(16),
        chainName: "Avalanche",
        nativeCurrency: {
            name: "AVAX",
            symbol: "AVAX",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[AVALANCHE],
        blockExplorerUrls: [getExplorerUrl(AVALANCHE)],
    },
    [AVALANCHE_FUJI]: {
        chainId: "0x" + AVALANCHE_FUJI.toString(16),
        chainName: "Avalanche Fuji Testnet",
        nativeCurrency: {
            name: "AVAX",
            symbol: "AVAX",
            decimals: 18,
        },
        rpcUrls: RPC_PROVIDERS[AVALANCHE_FUJI],
        blockExplorerUrls: [getExplorerUrl(AVALANCHE_FUJI)],
    },
}

export const getConstant = (chainId: number, key: string) => {
    if (!constants[chainId]) {
        throw new Error(`Unsupported chainId ${chainId}`)
    }

    if (!(key in constants[chainId])) {
        throw new Error(`Key ${key} does not exist for chainId ${chainId}`)
    }

    return constants[chainId][key]
}

export function getChainName(chainId: number) {
    return CHAIN_NAMES_MAP[chainId]
}

export function getRpcUrl(chainId: number): string | undefined {
    return sample(RPC_PROVIDERS[chainId])
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
    return sample(FALLBACK_PROVIDERS[chainId])
}

function getAlchemyKey() {
    if (ALCHEMY_WHITELISTED_DOMAINS.includes(self.location.host)) {
        return "RcaXYTizJs51m-w9SnRyDrxSZhE5H9Mf"
    }
    return "EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2"
}

export function getAlchemyArbitrumHttpUrl() {
    return `https://arb-mainnet.g.alchemy.com/v2/${getAlchemyKey()}`
}

export function getAlchemyAvalancheHttpUrl() {
    return `https://avax-mainnet.g.alchemy.com/v2/${getAlchemyKey()}`
}

export function getAlchemyArbitrumWsUrl() {
    return `wss://arb-mainnet.g.alchemy.com/v2/${getAlchemyKey()}`
}

export function getExplorerUrl(chainId) {
    switch (chainId) {
        case FRAX_TESTNET:
            return "https://holesky.fraxscan.io/"
        case CALABI_TESTNET:
            return "https://explorer.sepolia.mantle.xyz/"
        case FRAX_MAINNET:
            return "https://fraxscan.io/"
    }
    return "https://etherscan.io/"
}

export function getTokenExplorerUrl(chainId: number, tokenAddress: string) {
    return `${getExplorerUrl(chainId)}token/${tokenAddress}`
}

export function getHighExecutionFee(chainId) {
    return HIGH_EXECUTION_FEES_MAP[chainId] ?? 5
}

export function getExcessiveExecutionFee(chainId) {
    return EXCESSIVE_EXECUTION_FEES_MAP[chainId] ?? 10
}

export function isSupportedChain(chainId: number) {
    return SUPPORTED_CHAIN_IDS.includes(chainId)
}
