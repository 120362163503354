import { EvmPriceServiceConnection, Price, PriceFeed } from "@pythnetwork/pyth-evm-js"
import { USD_DECIMALS } from "config/factors"
import { DEPLOYMENTS } from "constants/deployments"
import { BN } from "fuels"
import { expandDecimals } from "lib/numbers"

class PythPriceClass {
    priceServiceConnection: EvmPriceServiceConnection
    prices: Record<string, Price> = {}
    initialized: boolean = false
    throttleInterval: number = 0
    updateTimes: Record<string, number> = {}
    lastUpdate: number = 0
    private subscribers: Set<(prices: Record<string, Price>, newLastUpdates: Record<string, number>) => void> = new Set()

    constructor(throttleInterval: number = 0.5 * 1000) {
        this.throttleInterval = throttleInterval

        this.priceServiceConnection = new EvmPriceServiceConnection("https://hermes.pyth.network")

        this.initializeAndSubscribe().then(() => (this.initialized = true))
    }

    subscribe = (callback: (prices: Record<string, Price>, newLastUpdates: Record<string, number>) => void) => {
        this.subscribers.add(callback)
        return () => this.subscribers.delete(callback)
    }

    assetIdToPricefeedId = (asset: string): string => {
        switch (asset) {
            case "0x0000000000000000000000000000000000000000":
            case "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1":
            case DEPLOYMENTS.frax_testnet.tokens.wETH:
            case DEPLOYMENTS.calabi_testnet.tokens.wETH:
                return DEPLOYMENTS.frax_testnet.pyth.ETH
            case DEPLOYMENTS.frax_testnet.tokens.BTC:
            case DEPLOYMENTS.calabi_testnet.tokens.BTC:
            case "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f":
                return DEPLOYMENTS.frax_testnet.pyth.BTC
            case "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8":
            case "0xaf88d065e77c8cC2239327C5EDb3A432268e5831":
                return DEPLOYMENTS.frax_testnet.pyth.USDC
            case DEPLOYMENTS.frax_testnet.tokens.FRAX:
            case DEPLOYMENTS.calabi_testnet.tokens.FRAX:
                return DEPLOYMENTS.frax_testnet.pyth.FRAX
            case undefined:
                break
            default:
                throw new Error(`[PythPrice.getIndexPriceFor] Asset "${asset}" not supported`)
        }

        return "0xlmaoPricefeedDoesn'tExist"
    }

    getLastUpdatedTimeFor = (asset: string): number => {
        const pricefeed = this.assetIdToPricefeedId(asset)

        return this.updateTimes[pricefeed]
    }

    getIndexPriceFor = (asset: string): BN => {
        if (!this.prices) return new BN(0)

        const pricefeed = this.assetIdToPricefeedId(asset)

        const feed = this.prices[pricefeed]

        if (!feed?.price) return new BN(0)

        const price = new BN(feed.price)

        // feed.expo is negative, so we add it to the USD_DECIMALS to get the correct number of decimals
        return new BN(expandDecimals(price.toString(), USD_DECIMALS + feed.expo).toString())
    }

    getIndexPriceWithSpread = (asset: string): { minPrice: BN; maxPrice: BN } => {
        if (!this.prices) return { minPrice: new BN(0), maxPrice: new BN(0) }

        const pricefeed = this.assetIdToPricefeedId(asset)

        const feed = this.prices[pricefeed]

        if (!feed?.price) return { minPrice: new BN(0), maxPrice: new BN(0) }

        const rawPrice = new BN(feed.price)
        const conf = new BN(feed.conf)

        const rawMinPrice = rawPrice.sub(conf)
        const rawMaxPrice = rawPrice.add(conf)

        // feed.expo is negative, so we add it to the USD_DECIMALS to get the correct number of decimals
        const minPrice = new BN(expandDecimals(rawMinPrice.toString(), USD_DECIMALS + feed.expo).toString())
        const maxPrice = new BN(expandDecimals(rawMaxPrice.toString(), USD_DECIMALS + feed.expo).toString())

        return { minPrice, maxPrice }
    }

    private initializeAndSubscribe = async () => {
        // Price ids: https://pyth.network/developers/price-feed-ids
        const priceIds = Object.values(DEPLOYMENTS.frax_testnet.pyth) as string[]

        const res = await this.priceServiceConnection.getLatestPriceFeeds(priceIds)

        this.prices = res?.reduce((acc, priceFeed) => {
            const price = priceFeed.getPriceUnchecked()
            return { ...acc, [`0x${priceFeed.id}`]: price }
        }, {} as any)

        await this.priceServiceConnection.subscribePriceFeedUpdates(priceIds, (priceFeed: PriceFeed) => {
            const now = Date.now()
            const key = `0x${priceFeed.id}`

            if (now - (this.updateTimes[key] || 0) >= this.throttleInterval) {
                const price = priceFeed.getPriceUnchecked()
                this.prices = { ...this.prices, [key]: price }
                this.updateTimes[key] = now

                // console.log("PRICES:", this.updateTimes)

                // Notify subscribers
                this.subscribers.forEach((callback) => callback(this.prices, this.updateTimes))
            }
        })
    }
}

export const PythPrice = new PythPriceClass(1000)
