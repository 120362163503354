import { useMemo } from "react"
import useSWR from "swr"

import { CHART_PERIODS } from "lib/legacy"
import { USD_DECIMALS } from "config/factors"
import { sleep } from "lib/sleep"
import { formatAmount } from "lib/numbers"
import { getNativeToken, getNormalizedTokenSymbol, isChartAvailableForToken } from "config/tokens"
import type { Bar, FromOldToNewArray } from "../tradingview/types"
import { FEED_ID_MAP, TIMEZONE_OFFSET_SEC } from "./constants"

export { TIMEZONE_OFFSET_SEC }

// https://docs.pyth.network/benchmarks
const PYTH_URL = "https://benchmarks.pyth.network/v1/shims/tradingview/history"
const PYTH_MAX_RANGE_IN_SEC = 365 * 24 * 60 * 60 // 1 year in seconds

// Use it to keep a record of the most recent bar on the chart
const LAST_BARS_CACHE = new Map()

type CompactBar = {
    t: number
    o: number
    c: number
    h: number
    l: number
}

function formatBarInfo(compactBar: CompactBar): Bar {
    const { t, o: open, c: close, h: high, l: low } = compactBar

    return {
        time: t + TIMEZONE_OFFSET_SEC,
        open,
        close,
        high,
        low,
    }
}

export function fillGaps(prices, periodSeconds) {
    if (prices.length < 2) {
        return prices
    }

    const newPrices = [prices[0]]
    let prevTime = prices[0].time
    for (let i = 1; i < prices.length; i++) {
        const { time, open } = prices[i]
        if (prevTime) {
            let j = (time - prevTime) / periodSeconds - 1
            while (j > 0) {
                newPrices.push({
                    time: time - j * periodSeconds,
                    open,
                    close: open,
                    high: open * 1.0003,
                    low: open * 0.9996,
                })
                j--
            }
        }

        prevTime = time
        newPrices.push(prices[i])
    }

    return newPrices
}

export async function getLimitChartPricesFromStats(
    chainId: number,
    symbol: string,
    period: string,
    limit = 1,
): Promise<FromOldToNewArray<Bar>> {
    symbol = getNormalizedTokenSymbol(symbol)

    if (!isChartAvailableForToken(chainId, symbol)) {
        symbol = getNativeToken(chainId).symbol
    }

    return [] //getChartPricesFromStats(chainId, symbol, period, limit)
    // try {
    //     const response = await fetch(url)
    //     if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`)
    //     }
    //     const prices = await response.json().then(({ prices }) => prices)
    //     return prices.map(formatBarInfo)
    // } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error(`Error fetching data: ${error}`)
    //     return []
    // }
}

export async function getChartPricesFromStats(
    chainId: number,
    symbol: string,
    period: string,
    limit = 1000,
): Promise<FromOldToNewArray<Bar>> {
    symbol = getNormalizedTokenSymbol(symbol)
    symbol = `Crypto.${symbol.toUpperCase()}/USD`

    const timeDiff = CHART_PERIODS[period] * 3000
    const NOW = Date.now()
    const from = Math.floor(NOW / 1000 - timeDiff)
    const to = Math.floor(NOW / 1000)

    const params = {
        symbol,
        resolution: CHART_PERIODS[period] / 60, // period is in minutes
        from: from.toString(),
        to: to.toString(),
    }

    const TIMEOUT = 5_000

    const rawPythPrices: any[] = await new Promise(async (resolve, reject) => {
        let done = false
        setTimeout(() => {
            done = true
            reject(new Error(`request timeout ${PYTH_URL}`))
        }, TIMEOUT)

        let lastErr

        for (let i = 0; i < 3; i++) {
            if (done) return
            try {
                const data = await fetchPythPrices(to, params)
                resolve(data)
                return
            } catch (ex) {
                console.error("ERROR:", ex)
                await sleep(300)
                lastErr = ex
            }
        }
        reject(lastErr)
    })

    if (!rawPythPrices || rawPythPrices.length < 1) {
        throw new Error(`not enough prices data: ${rawPythPrices?.length}`)
    }

    const prices = rawPythPrices.map(formatBarInfo)
    // console.log("PRICES:", prices)

    return prices
}

async function fetchPythPrices(to: number, params: any) {
    let promises = [] as any
    let currentFrom = params.from
    let currentTo

    while (currentFrom < to) {
        currentTo = Math.min(to, currentFrom + PYTH_MAX_RANGE_IN_SEC)
        params.from = currentFrom.toString()
        const url = `${PYTH_URL}?${new URLSearchParams(params)}`
        promises.push(fetch(url).then((response) => response.json()))
        currentFrom = currentTo
    }

    const allPythPrices = await Promise.all(promises)
        .then((results) => {
            const bars = [] as CompactBar[]
            results.forEach((data) => {
                if (data && data.t && data.t.length > 0) {
                    data.t.forEach((time, index) => {
                        bars.push({
                            t: time,
                            l: data.l[index],
                            h: data.h[index],
                            o: data.o[index],
                            c: data.c[index],
                        } as CompactBar)
                    })
                }
            })

            if (bars.length > 0) {
                LAST_BARS_CACHE.set(params.symbol, {
                    ...bars[bars.length - 1],
                })
            }

            return bars
        })
        .catch((error) => {
            console.log("[getBars]: Get error", error)
            return []
        })

    return allPythPrices
}

export function useChartPrices(chainId, symbol, isStable, period, currentAveragePrice) {
    const swrKey = !isStable && symbol ? ["getChartCandles", chainId, symbol, period] : null
    let { data: prices, mutate: updatePrices } = useSWR(swrKey, {
        fetcher: async () => {
            try {
                return await getChartPricesFromStats(chainId, symbol, period)
            } catch (ex) {
                console.error(ex)
                return []
            }
        },
        dedupingInterval: 60000,
        focusThrottleInterval: 60000 * 10,
    })

    const currentAveragePriceString = currentAveragePrice && currentAveragePrice.toString()
    const retPrices = useMemo(() => {
        if (isStable) {
            return getStablePriceData(period)
        }

        if (!prices) {
            return []
        }

        let _prices = [...prices]
        if (currentAveragePriceString && prices.length) {
            _prices = appendCurrentAveragePrice(_prices, BigInt(currentAveragePriceString), period)
        }

        return fillGaps(_prices, CHART_PERIODS[period])
    }, [prices, isStable, currentAveragePriceString, period])

    return [retPrices, updatePrices]
}

function appendCurrentAveragePrice(prices, currentAveragePrice, period) {
    const periodSeconds = CHART_PERIODS[period]
    const currentCandleTime = Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds + TIMEZONE_OFFSET_SEC
    const last = prices[prices.length - 1]
    const averagePriceValue = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2))
    if (currentCandleTime === last.time) {
        last.close = averagePriceValue
        last.high = Math.max(last.open, last.high, averagePriceValue)
        last.low = Math.min(last.open, last.low, averagePriceValue)
        return prices
    } else {
        const newCandle = {
            time: currentCandleTime,
            open: last.close,
            close: averagePriceValue,
            high: averagePriceValue,
            low: averagePriceValue,
        }
        return [...prices, newCandle]
    }
}

export function getStablePriceData(period, countBack = 100) {
    const periodSeconds = CHART_PERIODS[period]
    const now = Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds
    let priceData: any = []
    for (let i = countBack; i > 0; i--) {
        priceData.push({
            time: now - i * periodSeconds,
            open: 1,
            close: 1,
            high: 1,
            low: 1,
        })
    }
    return priceData
}
