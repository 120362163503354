import { Token } from "domain/tokens"
import { ethers } from "ethers"
import { ARBITRUM, ARBITRUM_GOERLI, AVALANCHE, AVALANCHE_FUJI, CALABI_TESTNET, FRAX_TESTNET } from "./chains"
import { getContract } from "./contracts"
import { DEPLOYMENTS } from "../constants/deployments"
import { isDevelopment } from "./env"

export const NATIVE_TOKEN_ADDRESS = ethers.ZeroAddress

export const TOKENS: { [chainId: number]: Token[] } = {
    [FRAX_TESTNET]: [
        {
            name: "Ethereum",
            symbol: "frxETH",
            decimals: 18,
            address: ethers.ZeroAddress,
            isNative: true,
            isShortable: true,
            imageUrl: "https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182",
            coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
            isV1Available: true,
        },
        {
            name: "Wrapped Frax",
            symbol: "frxETH",
            decimals: 18,
            address: DEPLOYMENTS.frax_testnet.tokens.wETH,
            isWrapped: true,
            baseSymbol: "ETH",
            imageUrl: "https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182",
            coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
            isV1Available: true,
        },
        {
            name: "Bitcoin (WBTC)",
            symbol: "BTC",
            assetSymbol: "WBTC",
            baseSymbol: "BTC",
            decimals: 18,
            address: DEPLOYMENTS.frax_testnet.tokens.BTC,
            isShortable: true,
            imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
            coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
            isV1Available: true,
        },
        {
            name: "FRAX",
            symbol: "FRAX",
            decimals: 18,
            address: DEPLOYMENTS.frax_testnet.tokens.FRAX,
            isStable: true,
            isV1Available: true,
            imageUrl: "https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182",
            coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
        },
    ],
    [CALABI_TESTNET]: [
        {
            name: "Ethereum",
            symbol: "ETH",
            decimals: 18,
            address: ethers.ZeroAddress,
            isNative: true,
            isShortable: true,
            imageUrl: "https://assets.coingecko.com/coins/images/30980/standard/token-logo.png?1696529819",
            coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
            isV1Available: true,
        },
        {
            name: "Wrapped ETH",
            symbol: "WETH",
            decimals: 18,
            address: DEPLOYMENTS.calabi_testnet.tokens.wETH,
            isWrapped: true,
            baseSymbol: "ETH",
            imageUrl: "https://assets.coingecko.com/coins/images/30980/standard/token-logo.png?1696529819",
            coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
            isV1Available: true,
        },
        {
            name: "Bitcoin (WBTC)",
            symbol: "BTC",
            assetSymbol: "WBTC",
            baseSymbol: "BTC",
            decimals: 18,
            address: DEPLOYMENTS.calabi_testnet.tokens.BTC,
            isShortable: true,
            imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
            coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
            isV1Available: true,
        },
        {
            name: "FRAX",
            symbol: "FRAX",
            decimals: 18,
            address: DEPLOYMENTS.calabi_testnet.tokens.FRAX,
            isStable: true,
            isV1Available: true,
            imageUrl: "https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182",
            coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
        },
    ],
    [ARBITRUM]: [
        {
            name: "Ethereum",
            symbol: "ETH",
            decimals: 18,
            address: ethers.ZeroAddress,
            isNative: true,
            isShortable: true,
            imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
            coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
            isV1Available: true,
        },
        {
            name: "Wrapped Ethereum",
            symbol: "WETH",
            decimals: 18,
            address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
            isWrapped: true,
            baseSymbol: "ETH",
            imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
            coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
            isV1Available: true,
        },
        {
            name: "Bitcoin (WBTC)",
            symbol: "BTC",
            assetSymbol: "WBTC",
            baseSymbol: "BTC",
            decimals: 8,
            address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
            isShortable: true,
            imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
            coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
            explorerUrl: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
            isV1Available: true,
        },
        {
            name: "Arbitrum",
            symbol: "ARB",
            decimals: 18,
            priceDecimals: 4,
            address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
            imageUrl: "https://assets.coingecko.com/coins/images/16547/small/photo_2023-03-29_21.47.00.jpeg?1680097630",
            coingeckoUrl: "https://www.coingecko.com/en/coins/arbitrum",
            explorerUrl: "https://arbiscan.io/token/0x912ce59144191c1204e64559fe8253a0e49e6548",
        },
        {
            name: "Bridged USDC (USDC.e)",
            symbol: "USDC.e",
            decimals: 6,
            address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
            isStable: true,
            imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
            coingeckoUrl: "https://www.coingecko.com/en/coins/bridged-usdc-arbitrum",
            explorerUrl: "https://arbiscan.io/token/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
            isV1Available: true,
        },
        {
            name: "USD Coin",
            symbol: "USDC",
            decimals: 6,
            address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
            isStable: true,
            isV1Available: true,
            imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
            coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
            explorerUrl: "https://arbiscan.io/address/0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
        },
    ],
}

export const TOKEN_COLOR_MAP = {
    ETH: "#6062a6",
    BTC: "#F7931A",
    WBTC: "#F7931A",
    USDC: "#2775CA",
    "USDC.e": "#2A5ADA",
    USDT: "#67B18A",
    MIM: "#9695F8",
    FRAX: "#000",
    DAI: "#FAC044",
    UNI: "#E9167C",
    AVAX: "#E84142",
    LINK: "#3256D6",
    DOGE: "#BA9F2F",
    SOL: "#38cbc1",
    ARB: "#162c4f",
    NEAR: "#07eb98",
    BNB: "#efb90b",
    ATOM: "#6f7390",
    XRP: "#23292f",
    LTC: "#16182e",
    OP: "#ff0421",
    DOT: "#e6007a",
    TBTC: "#000000",
    TEST: "#2d3ed7",
    default: "#6062a6",
}

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {}
export const V1_TOKENS: { [chainId: number]: Token[] } = {}
export const V2_TOKENS: { [chainId: number]: Token[] } = {}
export const SYNTHETIC_TOKENS: { [chainId: number]: Token[] } = {}
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {}
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {}
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {}

const CHAIN_IDS = [FRAX_TESTNET]
if (isDevelopment()) {
    CHAIN_IDS.push(ARBITRUM, CALABI_TESTNET)
}

for (let j = 0; j < CHAIN_IDS.length; j++) {
    const chainId = CHAIN_IDS[j]

    TOKENS_MAP[chainId] = {}
    TOKENS_BY_SYMBOL_MAP[chainId] = {}
    SYNTHETIC_TOKENS[chainId] = []
    V1_TOKENS[chainId] = []
    V2_TOKENS[chainId] = []

    let tokens = TOKENS[chainId]
    let wrappedTokenAddress: string | undefined

    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i]
        TOKENS_MAP[chainId][token.address] = token
        TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token

        if (token.isWrapped) {
            WRAPPED_TOKENS_MAP[chainId] = token
            wrappedTokenAddress = token.address
        }

        if (token.isNative) {
            NATIVE_TOKENS_MAP[chainId] = token
        }

        if (token.isV1Available && !token.isTempHidden) {
            V1_TOKENS[chainId].push(token)
        }

        if ((!token.isPlatformToken || (token.isPlatformToken && token.isPlatformTradingToken)) && !token.isTempHidden) {
            V2_TOKENS[chainId].push(token)
        }

        if (token.isSynthetic) {
            SYNTHETIC_TOKENS[chainId].push(token)
        }
    }

    NATIVE_TOKENS_MAP[chainId].wrappedAddress = wrappedTokenAddress
}

export function getSyntheticTokens(chainId: number) {
    return SYNTHETIC_TOKENS[chainId]
}

export function getWrappedToken(chainId: number) {
    return WRAPPED_TOKENS_MAP[chainId]
}

export function getNativeToken(chainId: number) {
    return NATIVE_TOKENS_MAP[chainId]
}

export function getTokens(chainId: number) {
    return TOKENS[chainId]
}

export function getV1Tokens(chainId: number) {
    return V1_TOKENS[chainId]
}

export function getV2Tokens(chainId: number) {
    return V2_TOKENS[chainId]
}

export function getTokensMap(chainId: number) {
    return TOKENS_MAP[chainId]
}

export function getWhitelistedV1Tokens(chainId: number) {
    return getV1Tokens(chainId)
}

export function getVisibleV1Tokens(chainId: number) {
    return getV1Tokens(chainId).filter((token) => !token.isWrapped)
}

export function isValidToken(chainId: number, address: string) {
    if (!TOKENS_MAP[chainId]) {
        throw new Error(`Incorrect chainId ${chainId}`)
    }
    return address in TOKENS_MAP[chainId]
}

export function getToken(chainId: number, address: string) {
    if (!TOKENS_MAP[chainId]) {
        throw new Error(`Incorrect chainId ${chainId}`)
    }
    if (!TOKENS_MAP[chainId][address]) {
        throw new Error(`Incorrect address "${address}" for chainId ${chainId}`)
    }

    return TOKENS_MAP[chainId][address]
}

export function getTokenBySymbol(
    chainId: number,
    symbol: string,
    {
        isSynthetic,
        version,
        symbolType = "symbol",
    }: { isSynthetic?: boolean; version?: "v1" | "v2"; symbolType?: "symbol" | "baseSymbol" } = {},
) {
    let tokens = Object.values(TOKENS_MAP[chainId])

    if (version) {
        tokens = version === "v1" ? getV1Tokens(chainId) : getV2Tokens(chainId)
    }

    let token: Token | undefined

    if (isSynthetic !== undefined) {
        token = tokens.find((token) => {
            return token[symbolType]?.toLowerCase() === symbol.toLowerCase() && Boolean(token.isSynthetic) === isSynthetic
        })
    } else {
        if (symbolType === "symbol" && TOKENS_BY_SYMBOL_MAP[chainId][symbol]) {
            token = TOKENS_BY_SYMBOL_MAP[chainId][symbol]
        } else {
            token = tokens.find((token) => token[symbolType]?.toLowerCase() === symbol.toLowerCase())
        }
    }

    if (!token) {
        throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`)
    }

    return token
}

export function convertTokenAddress(chainId: number, address: string, convertTo?: "wrapped" | "native") {
    const wrappedToken = getWrappedToken(chainId)

    if (convertTo === "wrapped" && address === NATIVE_TOKEN_ADDRESS) {
        return wrappedToken.address
    }

    if (convertTo === "native" && address === wrappedToken.address) {
        return NATIVE_TOKEN_ADDRESS
    }

    return address
}

export function getNormalizedTokenSymbol(tokenSymbol) {
    if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
        return tokenSymbol.substr(1)
    } else if (tokenSymbol.includes(".")) {
        return tokenSymbol.split(".")[0]
    }
    return tokenSymbol
}

export function isChartAvailableForToken(chainId: number, tokenSymbol: string) {
    let token

    try {
        token = getTokenBySymbol(chainId, tokenSymbol)
    } catch (e) {
        return false
    }

    if (token.isChartDisabled || (token.isPlatformToken && !token.isPlatformTradingToken)) return false

    return true
}

export function getPriceDecimals(chainId: number, tokenSymbol?: string) {
    if (!tokenSymbol) return 2

    try {
        const token = getTokenBySymbol(chainId, tokenSymbol)
        return token.priceDecimals ?? 2
    } catch (e) {
        return 2
    }
}

export function getTokenBySymbolSafe(chainId: number, symbol: string, params: Parameters<typeof getTokenBySymbol>[2] = {}) {
    try {
        return getTokenBySymbol(chainId, symbol, params)
    } catch (e) {
        return
    }
}

export function isTokenInList(token: Token, tokenList: Token[]): boolean {
    return tokenList.some((t) => t.address === token.address)
}

export function isSimilarToken(tokenA: Token, tokenB: Token) {
    if (tokenA.address === tokenB.address) {
        return true
    }

    if (tokenA.symbol === tokenB.symbol || tokenA.baseSymbol === tokenB.symbol || tokenA.symbol === tokenB.baseSymbol) {
        return true
    }

    return false
}
