export const TRIGGER_PREFIX_ABOVE = ">"
export const TRIGGER_PREFIX_BELOW = "<"

export const TOAST_AUTO_CLOSE_TIME = 7000
export const WS_LOST_FOCUS_TIMEOUT = 60_000
export const TRADE_LOST_FOCUS_TIMEOUT = 15_000

export const PERCENTAGE_SUGGESTIONS = [10, 25, 50, 75]
export const MAX_METAMASK_MOBILE_DECIMALS = 5
export const INPUT_LABEL_SEPARATOR = ":"

export const TRADE_HISTORY_PER_PAGE = 25
export const CLAIMS_HISTORY_PER_PAGE = 25
export const UI_FEE_RECEIVER_ACCOUNT = import.meta.env.VITE_APP_UI_FEE_RECEIVER || null

export const DEFAULT_TOOLTIP_POSITION = "bottom-start"

export const TOOLTIP_OPEN_DELAY = 100 // ms
export const TOOLTIP_CLOSE_DELAY = 100 // ms

export const MARKET_STATS_DECIMALS = 4
export const GM_POOL_PRICE_DECIMALS = 4
export const GLP_PRICE_DECIMALS = 4
export const GMX_PRICE_DECIMALS = 2

export const DATA_LOAD_TIMEOUT_FOR_METRICS = 10000

export const MAX_FEEDBACK_LENGTH = 500
