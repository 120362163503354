import { Contract, ContractRunner, ethers, InterfaceAbi } from "ethers"
import { DataStore__factory } from "typechain-types/factories/DataStore__factory"
import { ExchangeRouter__factory } from "typechain-types/factories/ExchangeRouter__factory"
import { Multicall__factory } from "typechain-types/factories/Multicall__factory"
import { ARBITRUM, AVALANCHE, CALABI_TESTNET, FRAX_TESTNET } from "./chains"
import { GlvRouter__factory } from "typechain-types"
import { DEPLOYMENTS } from "constants/deployments"

export const XGMT_EXCLUDED_ACCOUNTS = [
    "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
    "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
    "0xa633158288520807f91ccc98aa58e0ea43acb400",
    "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
]

const CONTRACTS = {
    [FRAX_TESTNET]: {
        Vault: DEPLOYMENTS.frax_testnet.perps.Vault,
        Router: DEPLOYMENTS.frax_testnet.perps.Router,
        VaultReader: DEPLOYMENTS.frax_testnet.perps.VaultReader,
        Reader: DEPLOYMENTS.frax_testnet.perps.Reader,
        GlpManager: DEPLOYMENTS.frax_testnet.perps.GlpManager,
        NATIVE_TOKEN: DEPLOYMENTS.frax_testnet.tokens.wETH,
        GLP: DEPLOYMENTS.frax_testnet.perps.GLP,
        GMX: DEPLOYMENTS.frax_testnet.perps.GMX,
        USDG: DEPLOYMENTS.frax_testnet.perps.USDG,

        RewardRouter: DEPLOYMENTS.frax_testnet.perps.RewardRouterV2,
        GlpRewardRouter: DEPLOYMENTS.frax_testnet.perps.RewardRouterV2,
        FeeGlpTracker: DEPLOYMENTS.frax_testnet.perps.FeeGlpTracker,
        RewardReader: DEPLOYMENTS.frax_testnet.perps.RewardReader,

        OrderBook: DEPLOYMENTS.frax_testnet.perps.OrderBook,
        OrderBookReader: DEPLOYMENTS.frax_testnet.perps.OrderBookReader,

        PositionRouter: DEPLOYMENTS.frax_testnet.perps.PositionRouter,
        PositionManager: DEPLOYMENTS.frax_testnet.perps.PositionManager,

        ReferralStorage: DEPLOYMENTS.frax_testnet.perps.ReferralStorage,
        ReferralReader: DEPLOYMENTS.frax_testnet.perps.ReferralReader,

        Multicall: DEPLOYMENTS.frax_testnet.misc.Multicall3,
        DataStore: DEPLOYMENTS.frax_testnet.misc.DataStore,
    },
    [CALABI_TESTNET]: {
        Vault: DEPLOYMENTS.calabi_testnet.perps.Vault,
        Router: DEPLOYMENTS.calabi_testnet.perps.Router,
        VaultReader: DEPLOYMENTS.calabi_testnet.perps.VaultReader,
        Reader: DEPLOYMENTS.calabi_testnet.perps.Reader,
        GlpManager: DEPLOYMENTS.calabi_testnet.perps.GlpManager,
        NATIVE_TOKEN: DEPLOYMENTS.calabi_testnet.tokens.wETH,
        GLP: DEPLOYMENTS.calabi_testnet.perps.GLP,
        GMX: DEPLOYMENTS.calabi_testnet.perps.GMX,
        USDG: DEPLOYMENTS.calabi_testnet.perps.USDG,

        RewardRouter: DEPLOYMENTS.calabi_testnet.perps.RewardRouterV2,
        GlpRewardRouter: DEPLOYMENTS.calabi_testnet.perps.RewardRouterV2,
        FeeGlpTracker: DEPLOYMENTS.calabi_testnet.perps.FeeGlpTracker,
        RewardReader: DEPLOYMENTS.calabi_testnet.perps.RewardReader,

        OrderBook: DEPLOYMENTS.calabi_testnet.perps.OrderBook,
        OrderBookReader: DEPLOYMENTS.calabi_testnet.perps.OrderBookReader,

        PositionRouter: DEPLOYMENTS.calabi_testnet.perps.PositionRouter,
        PositionManager: DEPLOYMENTS.calabi_testnet.perps.PositionManager,

        ReferralStorage: DEPLOYMENTS.calabi_testnet.perps.ReferralStorage,
        ReferralReader: DEPLOYMENTS.calabi_testnet.perps.ReferralReader,

        Multicall: DEPLOYMENTS.calabi_testnet.misc.Multicall3,
        DataStore: DEPLOYMENTS.calabi_testnet.misc.DataStore,
    },
    [ARBITRUM]: {
        // arbitrum mainnet
        Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
        Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
        VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
        Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
        GlpManager: "0x3963FfC9dff443c2A94f21b129D429891E32ec18",
        RewardRouter: "0x159854e14A862Df9E39E1D128b8e5F70B4A3cE9B",
        GlpRewardRouter: "0xB95DB5B167D75e6d04227CfFFA61069348d271F5",
        RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
        GovToken: "0x2A29D3a792000750807cc401806d6fd539928481",
        NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
        GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
        ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
        BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
        USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
        ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
        StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
        BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
        FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
        StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
        FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

        StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
        StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

        GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
        GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",
        AffiliateVester: "0x7c100c0F55A15221A4c1C5a25Db8C98A81df49B2",

        OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
        OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
        OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

        PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
        PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

        UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
        ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
        ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
        Timelock: "0xaa50bD556CE0Fe61D4A57718BA43177a3aB6A597",

        // Synthetics
        DataStore: "0xFD70de6b91282D8017aA4E741e9Ae325CAb992d8",
        EventEmitter: "0xC8ee91A54287DB53897056e12D9819156D3822Fb",
        SubaccountRouter: "0x9F48160eDc3Ad78F4cA0E3FDF54A75D8FB228452",
        ExchangeRouter: "0x69C527fC77291722b52649E45c838e41be8Bf5d5",
        DepositVault: "0xF89e77e8Dc11691C9e8757e84aaFbCD8A67d7A55",
        WithdrawalVault: "0x0628D46b5D145f183AdB6Ef1f2c97eD1C4701C55",
        OrderVault: "0x31eF83a530Fde1B38EE9A18093A333D8Bbbc40D5",
        ShiftVault: "0xfe99609C4AA83ff6816b64563Bdffd7fa68753Ab",
        SyntheticsReader: "0x23D4Da5C7C6902D4C86d551CaE60d5755820df9E",
        SyntheticsRouter: "0x7452c558d45f8afC8c83dAe62C3f8A5BE19c71f6",

        GlvReader: "0xd4f522c4339Ae0A90a156bd716715547e44Bed65",
        GlvRouter: "0x75eAFD2B4e306Dad8dd6334456F8018218Bc9882",
        GlvVault: "0x393053B58f9678C9c28c2cE941fF6cac49C3F8f9",

        Multicall: "0x842ec2c7d803033edf55e478f461fc547bc54eb2",
    },
    [AVALANCHE]: {
        // avalanche
        Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
        Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
        VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
        Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
        GlpManager: "0xD152c7F25db7F4B95b7658323c5F33d176818EE4",
        RewardRouter: "0xa192D0681E2b9484d1fA48083D36B8A2D0Da1809",
        GlpRewardRouter: "0xB70B91CE0771d3f4c81D87660f71Da31d48eB3B3",
        RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
        GovToken: "0x0ff183E29f1924ad10475506D7722169010CecCb",
        NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
        GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
        GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
        ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
        BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
        USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
        ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

        StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
        BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
        FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
        StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
        FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

        StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
        StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

        GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
        GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",
        AffiliateVester: "0x754eC029EF9926184b4CFDeA7756FbBAE7f326f7",

        OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
        OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
        OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

        PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
        PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

        TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
        ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
        ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
        Timelock: "0x8A68a039D555599Fd745f9343e8dE20C9eaFca75",

        // Synthetics
        DataStore: "0x2F0b22339414ADeD7D5F06f9D604c7fF5b2fe3f6",
        EventEmitter: "0xDb17B211c34240B014ab6d61d4A31FA0C0e20c26",
        SubaccountRouter: "0xe5485a4fD6527911e9b82A75A1bFEd6e47BE2241",
        ExchangeRouter: "0x3BE24AED1a4CcaDebF2956e02C27a00726D4327d",
        DepositVault: "0x90c670825d0C62ede1c5ee9571d6d9a17A722DFF",
        WithdrawalVault: "0xf5F30B10141E1F63FC11eD772931A8294a591996",
        OrderVault: "0xD3D60D22d415aD43b7e64b510D86A30f19B1B12C",
        ShiftVault: "0x7fC46CCb386e9bbBFB49A2639002734C3Ec52b39",
        SyntheticsReader: "0x95861eecD91Cb30220598DdA68268E7c1F1A1386",
        SyntheticsRouter: "0x820F5FfC5b525cD4d88Cd91aCf2c28F16530Cc68",

        Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    },
}

export function getContract(chainId: number, name: string): string {
    if (!CONTRACTS[chainId]) {
        throw new Error(`Unknown chainId ${chainId}`)
    }

    if (!CONTRACTS[chainId][name]) {
        throw new Error(`Unknown contract "${name}" for chainId ${chainId}`)
    }

    return CONTRACTS[chainId][name]
}

function makeGetContract<T extends { abi: InterfaceAbi; connect: (address: string) => unknown }>(name: string, factory: T) {
    return (chainId: number, provider?: ContractRunner) =>
        new Contract(getContract(chainId, name), factory.abi, provider) as unknown as ReturnType<T["connect"]>
}

export const getDataStoreContract = makeGetContract("DataStore", DataStore__factory)
export const getMulticallContract = makeGetContract("Multicall", Multicall__factory)
export const getExchangeRouterContract = makeGetContract("ExchangeRouter", ExchangeRouter__factory)
export const getGlvRouterContract = makeGetContract("GlvRouter", GlvRouter__factory)
