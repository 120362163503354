import connectWalletImg from "img/ic_wallet_24.svg"
import AddressDropdown from "../AddressDropdown/AddressDropdown"
import ConnectWalletButton from "../Common/ConnectWalletButton"

import { Trans } from "@lingui/macro"
import cx from "classnames"
import { ARBITRUM, ARBITRUM_GOERLI, AVALANCHE, AVALANCHE_FUJI, CALABI_TESTNET, FRAX_TESTNET, getChainName } from "config/chains"
import { isDevelopment } from "config/env"
import { getIcon } from "config/icons"
import { useChainId } from "lib/chains"
import { getAccountUrl, isHomeSite } from "lib/legacy"
import LanguagePopupHome from "../NetworkDropdown/LanguagePopupHome"
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown"
import { NotifyButton } from "../NotifyButton/NotifyButton"
import "./Header.scss"
import { HeaderLink } from "./HeaderLink"
import useWallet from "lib/wallets/useWallet"
import { useConnectModal } from "@rainbow-me/rainbowkit"

type Props = {
    openSettings: () => void
    small?: boolean
    disconnectAccountAndCloseSettings: () => void
    showRedirectModal: (to: string) => void
}

const NETWORK_OPTIONS = [
    {
        label: getChainName(FRAX_TESTNET),
        value: FRAX_TESTNET,
        icon: getIcon(FRAX_TESTNET, "network"),
        color: "#E841424D",
    },
]

if (isDevelopment()) {
    NETWORK_OPTIONS.push({
        label: getChainName(ARBITRUM),
        value: ARBITRUM,
        icon: getIcon(ARBITRUM, "network"),
        color: "#264f79",
    })

    NETWORK_OPTIONS.push({
        label: getChainName(CALABI_TESTNET),
        value: CALABI_TESTNET,
        icon: getIcon(CALABI_TESTNET, "network"),
        color: "#E841424D",
    })
}

export function AppHeaderUser({ openSettings, small, disconnectAccountAndCloseSettings, showRedirectModal }: Props) {
    const { chainId } = useChainId()
    const { active, account } = useWallet()
    const { openConnectModal } = useConnectModal()
    const showConnectionOptions = !isHomeSite()
    const selectorLabel = getChainName(chainId)

    if (!active || !account) {
        return (
            <div className="App-header-user">
                {showConnectionOptions && openConnectModal ? (
                    <>
                        <ConnectWalletButton onClick={openConnectModal} imgSrc={connectWalletImg}>
                            {small ? <Trans>Connect</Trans> : <Trans>Connect Wallet</Trans>}
                        </ConnectWalletButton>
                        <NetworkDropdown
                            small={small}
                            networkOptions={NETWORK_OPTIONS}
                            selectorLabel={selectorLabel}
                            openSettings={openSettings}
                        />
                    </>
                ) : (
                    <LanguagePopupHome />
                )}
            </div>
        )
    }

    const accountUrl = getAccountUrl(chainId, account)

    return (
        <div className="App-header-user">
            {showConnectionOptions ? (
                <>
                    <div className="App-header-user-address" data-qa="user-address">
                        <AddressDropdown
                            account={account}
                            accountUrl={accountUrl}
                            disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
                        />
                    </div>
                    <NetworkDropdown
                        small={small}
                        networkOptions={NETWORK_OPTIONS}
                        selectorLabel={selectorLabel}
                        openSettings={openSettings}
                    />
                </>
            ) : (
                <LanguagePopupHome />
            )}
        </div>
    )
}
