// Ethereum network, Chainlink Aggregator contracts
export const FEED_ID_MAP = {
    BTC_USD: "0xae74faa92cb67a95ebcab07358bc222e33a34da7",
    ETH_USD: "0xe62b71cf983019bff55bc83b48601ce8419650cc",
    BNB_USD: "0xc45ebd0f901ba6b2b8c7e70b717778f055ef5e6d",
    LINK_USD: "0xdfd03bfc3465107ce570a0397b247f546a42d0fa",
    UNI_USD: "0x68577f915131087199fe48913d8b416b3984fd38",
    SUSHI_USD: "0x7213536a36094cd8a768a5e45203ec286cba2d74",
    AVAX_USD: "0x0fc3657899693648bba4dbd2d8b33b82e875105d",
    AAVE_USD: "0xe3f0dede4b499c07e12475087ab1a084b5f93bc0",
    YFI_USD: "0x8a4d74003870064d41d4f84940550911fbfccf04",
    SPELL_USD: "0x8640b23468815902e011948f3ab173e1e83f9879",
}
export const TIMEZONE_OFFSET_SEC = -new Date().getTimezoneOffset() * 60
