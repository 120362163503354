/* 
  This files is used to pre-build data during the build process.
  Avoid adding client-side code here, as it can break the build process.

  However, this files can be a dependency for the client code.
*/

export const BSC_MAINNET = 56
export const BSC_TESTNET = 97
export const ETH_MAINNET = 1
export const AVALANCHE = 43114
export const AVALANCHE_FUJI = 43113
export const ARBITRUM = 42161
export const ARBITRUM_GOERLI = 421613
export const FEES_HIGH_BPS = 50
export const DEFAULT_ALLOWED_SLIPPAGE_BPS = 30

export const FRAX_TESTNET = 2522
export const FRAX_MAINNET = 252

export const CALABI_TESTNET = 5003
