import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import ClpSwap from "components/Clp/ClpSwap"
import Footer from "components/Footer/Footer"
import "./BuyClp.css"

import { Trans, t } from "@lingui/macro"
import { getNativeToken } from "config/tokens"
import { useChainId } from "lib/chains"
import ExternalLink from "components/ExternalLink/ExternalLink"
import PageTitle from "components/PageTitle/PageTitle"
import useIncentiveStats from "domain/synthetics/common/useIncentiveStats"
import { getIncentivesV2Url } from "config/links"

export default function BuyClp() {
    const { chainId } = useChainId()
    const history = useHistory()
    const [isBuying, setIsBuying] = useState(true)
    const nativeTokenSymbol = getNativeToken(chainId).symbol
    const incentiveState = useIncentiveStats()

    useEffect(() => {
        const hash = history.location.hash.replace("#", "")
        const buying = hash === "redeem" ? false : true
        setIsBuying(buying)
    }, [history.location.hash])

    return (
        <div className="default-container page-layout">
            <PageTitle
                title={`CLP Pool`}
                isTop
                qa="buy-glp-page"
                subtitle={
                    <div>
                        {incentiveState?.migration?.isActive && (
                            <div>
                                <Trans>
                                    GLP to GM migration has reduced Fees due to STIP incentives.{" "}
                                    <ExternalLink href={getIncentivesV2Url(chainId)}>Read more</ExternalLink>.
                                </Trans>
                            </div>
                        )}
                        <Trans>
                            Purchase{" "}
                            <ExternalLink href="https://docs.gmx.io/docs/providing-liquidity/v1">GLP tokens</ExternalLink> to earn{" "}
                            {nativeTokenSymbol} fees from swaps and leverage trading.
                        </Trans>
                    </div>
                }
            />
            <ClpSwap isBuying={isBuying} setIsBuying={setIsBuying} />
            <Footer />
        </div>
    )
}
