import { Price } from "@pythnetwork/pyth-evm-js"
import { useEffect, useState } from "react"
import { PythPrice } from "../pyth-price/PythPrice"

type AssetId = string

export function usePythPrices() {
    const [prices, setPrices] = useState<Record<AssetId, Price>>({})
    const [updateTimes, _] = useState<Record<string, number>>({})
    const [lastUpdate, setLastUpdate] = useState<number>(0)

    useEffect(() => {
        const unsubsribe = PythPrice.subscribe((newPrices: Record<string, Price>, newLastUpdates: Record<string, number>) => {
            setPrices(newPrices)
            setLastUpdate(Date.now())
        })

        return unsubsribe as any
    }, [])

    return { prices, updateTimes, lastUpdate }
}
