import { getToken } from "config/tokens"
import { createSelector } from "../utils"
import { selectChainId, selectTokensData } from "./globalSelectors"
import {
    selectTradeboxAvailableTokensOptions,
    selectTradeboxFromTokenAddress,
    selectTradeboxMarketInfo,
    selectTradeboxToTokenAddress,
    selectTradeboxTradeFlags,
} from "./tradeboxSelectors"
import { getTokenData } from "domain/synthetics/tokens"
import { getAvailableUsdLiquidityForPosition } from "domain/synthetics/markets"
import { getBorrowingFactorPerPeriod } from "domain/synthetics/fees"
import { getFundingFactorPerPeriod } from "../../../domain/synthetics/fees/utils/index"
import { CHART_PERIODS } from "lib/legacy"
import { bigMath } from "lib/bigmath"

export const selectChartToken = createSelector(function selectChartToken(q) {
    const fromTokenAddress = q(selectTradeboxFromTokenAddress)
    const toTokenAddress = q(selectTradeboxToTokenAddress)

    if (!fromTokenAddress || !toTokenAddress) {
        return undefined
    }

    const chainId = q(selectChainId)
    const { isSwap } = q(selectTradeboxTradeFlags)

    try {
        const fromToken = getToken(chainId, fromTokenAddress)
        const toToken = getToken(chainId, toTokenAddress)
        const chartToken = isSwap && toToken?.isStable && !fromToken?.isStable ? fromToken : toToken
        const tokensData = q(selectTokensData)

        return getTokenData(tokensData, chartToken?.address)
    } catch (e) {
        return undefined
    }
})

export const selectAvailableChartTokens = createSelector(function selectChartToken(q) {
    const fromTokenAddress = q(selectTradeboxFromTokenAddress)
    const toTokenAddress = q(selectTradeboxToTokenAddress)

    if (!fromTokenAddress || !toTokenAddress) {
        return []
    }

    const { isSwap } = q(selectTradeboxTradeFlags)
    const { swapTokens, indexTokens, sortedLongAndShortTokens, sortedIndexTokensWithPoolValue } = q(
        selectTradeboxAvailableTokensOptions,
    )

    const availableChartTokens = isSwap ? swapTokens : indexTokens
    const sortedAvailableChartTokens = availableChartTokens.sort((a, b) => {
        if (sortedIndexTokensWithPoolValue || sortedLongAndShortTokens) {
            const currentSortReferenceList = isSwap ? sortedLongAndShortTokens : sortedIndexTokensWithPoolValue
            return currentSortReferenceList.indexOf(a.address) - currentSortReferenceList.indexOf(b.address)
        }
        return 0
    })

    return sortedAvailableChartTokens
})

export const selectChartHeaderInfo = createSelector((q) => {
    const marketInfo = q(selectTradeboxMarketInfo)

    if (!marketInfo) {
        return
    }

    const borrowingRateLong = -getBorrowingFactorPerPeriod(marketInfo, true, CHART_PERIODS["1h"])
    const borrowingRateShort = -getBorrowingFactorPerPeriod(marketInfo, false, CHART_PERIODS["1h"])
    const fundingRateLong = getFundingFactorPerPeriod(marketInfo, true, CHART_PERIODS["1h"])
    const fundingRateShort = getFundingFactorPerPeriod(marketInfo, false, CHART_PERIODS["1h"])

    const netRateHourlyLong = (fundingRateLong ?? 0n) + (borrowingRateLong ?? 0n)
    const netRateHourlyShort = (fundingRateShort ?? 0n) + (borrowingRateShort ?? 0n)

    const longUsdVolume = marketInfo.longInterestUsd
    const totalVolume = marketInfo.longInterestUsd + marketInfo.shortInterestUsd

    const longOpenInterestPercentage =
        totalVolume !== 0n ? Math.max(Math.min(Number(bigMath.mulDiv(longUsdVolume, 100n, totalVolume)), 100), 0.01) : 0

    const shortOpenInterestPercentage =
        totalVolume === 0n ? 0 : longOpenInterestPercentage !== undefined ? 100 - longOpenInterestPercentage : undefined

    return {
        liquidityLong: getAvailableUsdLiquidityForPosition(marketInfo, true),
        liquidityShort: getAvailableUsdLiquidityForPosition(marketInfo, false),
        netRateHourlyLong,
        netRateHourlyShort,
        borrowingRateLong,
        borrowingRateShort,
        fundingRateLong,
        fundingRateShort,
        openInterestLong: marketInfo.longInterestUsd,
        openInterestShort: marketInfo.shortInterestUsd,
        decimals: marketInfo.indexToken.decimals,
        longOpenInterestPercentage,
        shortOpenInterestPercentage,
    }
})
