export const SUPPORTED_RESOLUTIONS_V1 = { 5: "5m", 15: "15m", 60: "1h", 240: "4h", "1D": "1d" }
export const SUPPORTED_RESOLUTIONS_V2 = {
    1: "1m",
    5: "5m",
    15: "15m",
    60: "1h",
    240: "4h",
    "1D": "1d",
    "1W": "1w",
    "1M": "1M",
}
export const LAST_BAR_FETCH_INTERVAL = 1000 // 1 second
