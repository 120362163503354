import { calculatePriceDecimals } from "lib/numbers"
import { selectMarketsInfoData, selectTokensData } from "context/SyntheticsStateContext/selectors/globalSelectors"
import { marketsInfoData2IndexTokenStatsMap } from "domain/synthetics/stats/marketsInfoDataToIndexTokensStats"
import { EMPTY_ARRAY, getByKey } from "lib/objects"
import { createSelector, createSelectorFactory } from "../utils"
import { selectChartToken } from "./chartSelectors"

export const selectIndexTokenStats = createSelector((q) => {
    const marketsInfoData = q(selectMarketsInfoData)

    if (!marketsInfoData) {
        return EMPTY_ARRAY
    }

    const stats = q(selectIndexTokenStatsMap)

    return stats.sortedByTotalPoolValue.map((address) => stats.indexMap[address]!)
})

const FALLBACK: ReturnType<typeof marketsInfoData2IndexTokenStatsMap> = {
    indexMap: {},
    sortedByTotalPoolValue: [],
}

export const selectIndexTokenStatsMap = createSelector((q) => {
    const marketsInfoData = q(selectMarketsInfoData)

    if (!marketsInfoData) {
        return FALLBACK
    }

    return marketsInfoData2IndexTokenStatsMap(marketsInfoData)
})

export const selectSelectedMarketPriceDecimals = createSelector((q) => {
    const token = q(selectChartToken)

    if (!token) {
        return 2
    }

    return calculatePriceDecimals(token.prices.minPrice)
})

export const makeSelectMarketPriceDecimals = createSelectorFactory((tokenAddress?: string) =>
    createSelector(function selectSelectedMarketPriceDecimals(q) {
        const tokensData = q(selectTokensData)
        const token = getByKey(tokensData, tokenAddress)

        if (!token) {
            return
        }

        return calculatePriceDecimals(token.prices.minPrice)
    }),
)
